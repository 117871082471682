import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { INavigation } from '../../../../interfaces/navigation.interface';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() navigation: INavigation[];
  expand: boolean;

  constructor(private route: Router) {}

  onClickRoute(route: string) {
    this.route.navigate([route]);
  }

  onToggle() {
    this.expand = !this.expand;
  }
}
