<mat-sidenav-container class="portfolio-container">
    <mat-sidenav #sidenav [mode]="'over'">
        <p class="text-right">
            <button mat-button (click)="sidenav.toggle()"><mat-icon>close</mat-icon></button>
        </p>
        <mat-list-item *ngFor="let nav of navigation">
            <button
                mat-button
                class="text-white w-100 text-left mb-4"
                (click)="onClickRoute(nav.link)"
                [matTooltipPosition]="'right'"
                [matTooltip]="nav.name"
            >
                <mat-icon [ngClass]="nav.icon" class="mr-2"></mat-icon>
                <span *ngIf="expand">{{ nav.name }}</span>
            </button>
        </mat-list-item>
        <mat-list-item>
            <button
                class="w-100"
                mat-button
                (click)="onToggle()"
                [matTooltipPosition]="'right'"
                [matTooltip]="!expand ? 'Expand' : 'Collapse'"
            >
                <mat-icon>{{ !expand ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }}</mat-icon>
            </button>
        </mat-list-item>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar class="position-fixed">
            <span class="mr-auto"></span>
            <button mat-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
